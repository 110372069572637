<template>
  <div class="bg-white shadow mt-3 p-3 rounded mt-2">
    <h2 class="text-grey-light text-mdl font-bold font-headers mt-1 mb-2">
      Team Engagement
    </h2>
    <div class="mb-2">
      <p class="text-mdh text-grey-dark-1 font-bold">Participants statuses:</p>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-yellow-medium rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-yellow-medium">Invited</span> = users that have been
          sent emails to join the platform
        </p>
      </div>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-green-2 rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-green-2">Active</span> = registered users
        </p>
      </div>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-red rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-red">Deactivated</span> = user accounts that have
          been deactivated by admins
        </p>
      </div>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-purple rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-purple">Deleted</span> = user accounts that have
          personal information removed
        </p>
      </div>
    </div>
    <div>
      <p class="text-md mx-2">
        Showing {{ this.teamProgress.length }} Participants
      </p>
    </div>
    <div class="flex justify-between items-center mx-2 mt-1">
      <InputField
        placeholder="Name, job role or team"
        label="Filter by"
        :propValue="this.filter.keyword"
        :onChange="setKeyword"
        leftIcon
        class="w-inputField"
        data-testid="filter-field"
      />

      <div class="w-2/4 flex flex-row justify-between items-end">
        <Select
          :options="this.filters"
          :onSelect="setValue"
          label="Filter by Status"
          objects
          class="ml-auto mr-2"
        />
        <Button
          text="Export Data"
          type="primary"
          size="medium"
          :iconLeft="downloadIcon"
          @click.native="exportPeopleData"
        />
      </div>
    </div>
    <div>
      <Table :tableData="teamProgress" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadIcon from "@/assets/img/icons/download.svg";
import InputField from "../../../../components/InputField/InputField.vue";
import Table from "./Table";

export default {
  name: "TeamEngagement",
  components: {
    Table, InputField
  },
  data: () => ({
    downloadIcon,
    filter: {
      label: "All statuses",
      value: "ALL",
      keyword: "",
    },
    filters: [
      {
        label: "All statuses",
        value: "ALL",
      },
      {
        label: "Not started",
        value: "NOT_STARTED",
      },
      {
        label: "In progress",
        value: "IN_PROGRESS",
      },
      {
        label: "Completed",
        value: "COMPLETED",
      },
    ],
  }),
  methods: {
    ...mapActions({
      exportPeople: "companyResults/exportSurveyPeople",
    }),
    setValue(option) {
      this.filter.value = option.value;
      this.filter.label = option.label;
    },
    exportPeopleData() {
      this.exportPeople({ surveyId: this.$route.params.id, status: this.formatFilter });
    },
    setKeyword(value) {
      this.filter.keyword = value;
    }
  },
  computed: {
    ...mapGetters({
      filteredTeam: "companyResults/filteredTeam",
    }),
    teamProgress() {
      return this.filteredTeam(this.filter.value, this.filter.keyword);
    },
    formatFilter() {
      switch (this.filter.label) {
        case "All statuses":
          return '';
        case "Not started":
          return 1;
        case "In progress":
          return 2;
        case "Completed":
          return 3;
        default:
          return;
      }
    },
  },
};
</script>
