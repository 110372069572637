<template>
  <div class="mb-5" v-if="this.assessment">
    <div class="mt-3 flex items-center justify-between">
      <div>
        <h1 class="text-xl text-grey-light font-medium font-headers mb-3">
          <span v-show="!this.inProgress"> Company Results / </span>
          <span class="font-bold text-grey-dark-3 pt-1">
            Assessment Overview
          </span>
          <span
            v-if="hasLaunched"
            class="border border-blue ml-2 rounded text-sm-2 p-0.5 text-blue align-middle"
          >
            In Progress
          </span>
          <span
            v-else-if="hasNotLaunched"
            class="border border-red ml-2 rounded text-sm-2 p-0.5 text-red align-middle"
          >
            Not Launched
          </span>
        </h1>
      </div>
      <div v-show="!this.inProgress" class="flex flex-row space-x-2">
        <div v-if="this.assessment.resultsPublished !== null">
          <Button
            text="Release Results"
            type="primary-white"
            size="medium"
            @click.native="publishResults"
            v-if="!this.assessment.resultsPublished"
          />
          <div v-else class="align-center items-center flex">
            <p class="text-sm-2 text-grey-light">Results have been released</p>
          </div>
        </div>
        <DownloadButton
          :hasExportPermission="hasExportRights"
          @download="downloadPdf"
          @export="exportXLS"
        />
      </div>
    </div>
    <div class="mt-3 flex space-x-2" v-if="!this.inProgress">
      <div class="w-1/4">
        <Select
          :options="this.assessmentsOptions"
          :onSelect="setAssessmentValue"
          :value="selectedAssessment"
          label="Assessment Name"
          :preselectFirst="false"
          objects
        />
      </div>
      <div class="w-1/4">
        <Select
          :options="this.teamsOptions"
          :onSelect="setTeamValue"
          :value="selectedTeam"
          :preselectFirst="false"
          label="Team"
          objects
        />
      </div>
      <div v-if="isIndividual" class="w-1/4">
        <Select
          label="Job Role"
          :onSelect="setJobRole"
          :options="this.jobOptions"
          :value="selectedJobrole"
          :preselectFirst="false"
          objects
        />
      </div>
      <div class="w-1/4">
        <Select
          label="Employee Name"
          :options="this.employeesOptions"
          :value="selectedMember"
          :onSelect="setEmployee"
          objects
        />
      </div>
    </div>
    <div class="mt-2 space-x-2 flex" v-if="this.inProgress">
      <SmallInfoCard
        v-for="(stat, index) in surveyStats"
        :data="stat"
        :icon="stat.icon"
        :key="index"
      />
    </div>
    <div class="mt-2 bg-white">
      <UserCard :member="member" v-if="member" />
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded" v-if="assessment">
      <div class="flex flex-row space-x-5 xl:space-x-11">
        <div>
          <p class="text-sm-2 text-grey-light">Assessment Name</p>
          <p class="text-grey-dark-1 text-md-2 font-medium mt-0.4 max-w-300">
            {{ assessment.title }}
            {{ displayVersionForSeller(assessment.productVersion)}}
          </p>
        </div>
        <div>
          <p class="text-sm-2 text-grey-light">Launch Date</p>
          <p class="text-grey-dark-1 text-md-2 font-medium mt-0.4">
            {{ assessment.launchedOn }}
          </p>
        </div>
        <div>
          <p class="text-sm-2 text-grey-light">Close Date</p>
          <p class="text-grey-dark-1 text-md-2 font-medium mt-0.4">
            {{ assessment.dueOn }}
          </p>
        </div>
      </div>
      <div class="flex flex-row mt-3">
        <div>
          <p class="text-sm-2 text-grey-light">About Your Results</p>
          <p class="text-grey-dark-1 text-md mt-1 lg:w-1/2 md:w-3/2 pr-4">
            The Assessment is based on a comprehensive competency-based
            capability model comprised of core competencies and sub-competencies
            for high-performing practitioners worldwide.
          </p>
          <div class="text-grey-dark-1 text-md mt-4 w-1/2 pr-4">
            <p>Competency Level</p>
            <div class="mt-1 flex flex-wrap">
              <p
                v-for="(item) in competencyLevels"
                :key="item.score"
                class="mr-3"
              >
                <span class="font-bold">{{ item.score }}.0</span>
                = {{ item.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 space-x-2 flex" v-if="!this.inProgress && !this.member">
      <SmallInfoCard
        v-for="(stat, index) in surveyStats"
        :data="stat"
        :icon="stat.icon"
        :key="index"
      />
    </div>
    <TeamEngagement v-show="inProgress" />
    <div class="bg-white shadow mt-3 p-3 rounded mt-2" v-if="competencyResults">
      <p class="font-headers text-grey-light text-mdl font-bold mt-1">
        {{
          inProgress
            ? "Indicative Results and Target Scores for Competencies"
            : "Results and Target Scores for Competencies"
        }}
      </p>
      <div class="mt-2">
        <LineBarChart
          v-if="competencyResults.barData.data.length !== 0"
          :categories="competencyResults.categories"
          :barData="competencyResults.barData"
          :lineData="competencyResults.lineData"
          :plotSpace="60"
          height="450"
          :yAxisMaxValue="yAxisMaxValue"
          :numDivLines="numDivLines"
          id="competency-chart"
        />
        <p v-else class="ml-2 text-mdh text-grey-dark-1">No results yet.</p>
      </div>
    </div>
    <div class="bg-white shadow mt-2 p-3 rounded" id="compDelta">
      <DeltaTable
        v-if="competencyResults && competencyResults.tableData.length !== 0"
        type="competency"
        :title="
          inProgress
            ? 'Indicative Detailed Results by Core Competency'
            : 'Overall Results by Core Competency'
        "
        :tableData="competencyResults.tableData"
      />
      <div v-else>
        <p class="text-grey-light text-mdl font-bold font-headers mt-1 mb-2">
          Indicative Detailed Results by Core Competency
        </p>
        <p class="ml-2 text-mdh text-grey-dark-1 mb-2.5">
          The 16 core competencies from largest to smallest
          <span class="font-bold">delta</span> (Actual Score - Target Score).
        </p>
        <p class="ml-2 text-mdh text-grey-dark-1">No results yet.</p>
      </div>
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded mt-2">
      <p class="font-headers text-grey-light text-mdl font-bold mt-1">
        {{
          inProgress
            ? "Indicative Company Results and Target Scores for Sub-competency"
            : "Results and Target Scores for Sub-competencies"
        }}
      </p>
      <div
        class="mt-2"
        v-if="
          subcompetencyResults && subcompetencyResults.barData.data.length !== 0
        "
      >
        <LineBarChart
          :categories="subcompetencyResults.categories"
          :barData="subcompetencyResults.barData"
          :lineData="subcompetencyResults.lineData"
          :plotSpace="15"
          :yAxisMaxValue="yAxisMaxValue"
          :numDivLines="numDivLines"
          height="450"
          :hideLegend="true"
          :key="key"
          id="subcompetency-chart"
        />
        <StaticLegend
          class="mt-3 ml-2"
          :withManagerial="false"
          :legend="subcompetencyLegend"
          :withTeam="false"
        />
      </div>
      <div v-else class="mt-2">
        <p class="ml-2 text-mdh text-grey-dark-1">No results yet.</p>
      </div>
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded mt-2" id="subcompDelta">
      <DeltaTable
        type="subcompetency"
        v-if="
          subcompetencyResults && subcompetencyResults.tableData.length !== 0
        "
        :title="
          inProgress
            ? 'Indicative Detailed Company Results by Sub-Competency'
            : 'Overall Results by Sub-competency'
        "
        :tableData="subcompetencyResults.tableData"
      />
      <div v-else>
        <p class="text-grey-light text-mdl font-bold font-headers mt-1 mb-2">
          Indicative Detailed Company Results by Sub-Competency
        </p>
        <p class="ml-2 text-mdh text-grey-dark-1">No results yet.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import scrollToTop from "../../../services/utils/scrollToTop";
import DeltaTable from "@/components/DeltaTable/DeltaTable";
import UserCard from "@/components/miscellaneous/UserCard";
import Stats from "../components/Stats";
import { employees } from "../mock/employees";
import StaticLegend from "@/components/Charts/StaticLegend.vue";
import TeamEngagement from "../components/TeamEngagement/index";
import downloadIcon from "@/assets/img/icons/download.svg";
import DownloadButton from '../components/DownloadButton.vue';
import FusionCharts from "fusioncharts";

export default {
  name: "CompanyAssessmentResult",
  components: {
    DeltaTable,
    Stats,
    UserCard,
    StaticLegend,
    TeamEngagement,
    DownloadButton
},
  data: () => ({
    employees,
    downloadIcon,
    compData: {},
    subCompData: {},
    userLine: [],
    selectedEmployee: null,
    selectedAssessment: null,
    selectedTeam: { label: "All Participants", id: -1 },
    selectedMember: { label: "All Employees", id: 0 },
    selectedJobrole: { label: "All Job Roles", id: 0 },
    key: Math.random(),
    roles: [
      {
        label: "All roles",
        id: -1,
      },
      {
        label: "Procurement Specialist",
        id: 0,
      },
      {
        label: "Analyst",
        id: 1,
      },
      {
        label: "Senior Buyer",
        id: 2,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      getMemberDetails: "companyResults/getMemberDetails",
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
    }),
    inProgress() {
      return this.assessment.inProgress;
    },
    hasExportRights() {
      return (this.isImpersonating || this.details.exportPermission);
    },
    ...mapState({
      assessments: (state) => state.companyResults.assessments,
      subcompetencyLegend: (state) => state.companyResults.subcompetencyLegend,
      teams: (state) => state.companyResults.teams,
      surveyStats: (state) => state.companyResults.surveyStats,
      assessment: (state) => state.companyResults.assessment,
      competencyResults: (state) => state.companyResults.competencyResults,
      subcompetencyResults: (state) => state.companyResults.subcompetencyResults,
      jobRoles: (state) => state.companyResults.jobRoles,
      members: (state) => state.companyResults.surveyMembers,
      details: (state) => state.subscriptions.form,
    }),
    isIndividual() {
      return this.assessment?.surveyType === 0;
    },
    isOrganizational() {
      return this.assessment?.surveyType === 1;
    },
    yAxisMaxValue() {
      return this.assessment.competencyLevels.length - 1;
    },
    numDivLines() {
      return (this.assessment.competencyLevels.length - 1) * 2 - 1;
    },
    competencyLevels() {
      return this.assessment.competencyLevels;
    },
    member() {
      if (this.selectedMember.id !== 0)
        return this.getMemberDetails(this.selectedMember.id);
    },
    hasLaunched() {
      if (
        this.inProgress &&
        +new Date(this.assessment.launchedOn) < +new Date()
      )
        return true;
      return false;
    },
    isClosed() {
      if (+new Date(this.assessment.dueOn) < +new Date()) return true;
      return false;
    },
    hasNotLaunched() {
      if (+new Date(this.assessment.launchedOn) > +new Date()) return true;
      return false;
    },
    assessmentsOptions() {
      return this.assessments.map((el) => ({
        label: el.name + this.displayVersionForSeller(el.productVersion),
        id: el.id,
      }));
    },
    teamsOptions() {
      return [
        { label: "All Participants", id: -1 },
        { label: "Unassigned", id: 0 },
        ...this.teams.map((el) => ({
          label: el.name,
          id: el.id,
        })),
      ];
    },
    jobOptions() {
      return [
        { label: "All Job Roles", id: 0 },
        ...this.jobRoles.map((el) => ({
          label: el.name,
          id: el.id,
        })),
      ];
    },
    employeesOptions() {
      return [
        { label: "All Employees", id: 0 },
        ...this.members.map((el) => ({
          label: el.firstName + " " + el.lastName,
          id: el.id,
          class: el.firstName === "Anonymised" ? "text-grey-medium-2" : "",
        })),
      ];
    },
    filteredStats() {
      return this.assessment.stats.find((el) => el.group === this.selectedRole);
    },
    renderUserLine() {
      if (this.selectedEmployee && this.selectedEmployee.roleGroup !== -1)
        return userLine;
      return null;
    },
    renderUserCompLine() {
      if (this.selectedEmployee && this.selectedEmployee.roleGroup !== -1)
        return this.compData.lineData3;
      return null;
    },
  },
  watch: {
    $route(_, from) {
      if (from.name === "CompanyAssessmentResult") {
        this.init();
      } else {
        this.fetchResults();
      }
    },
  },
  methods: {
    ...mapActions({
      resetTeamResults: "teamResults/resetTeamResults",
      getSurveyStats: "companyResults/getSurveyStats",
      getProgress: "companyResults/getProgress",
      getAssessment: "companyResults/getAssessment",
      releaseResults: "companyResults/publishResults",
      getAssessmentCompetencyResults:
        "companyResults/getAssessmentCompetencyResults",
      getAssessmentSubcompetencyResults:
        "companyResults/getAssessmentSubcompetencyResults",
      getCompanyAssessments: "companyResults/getCompanyAssessments",
      getJobRoles: "companyResults/getJobRoles",
      getTeamsForAssessment: "companyResults/getTeamsForAssessment",
      getMembersOfSurvey: "companyResults/getMembersOfSurvey",
      download: "teamResults/downloadTeamPdf",
      export: "companyResults/exportTeamXLS",
    }),
    exportChart(chartName) {
      const chart = FusionCharts(chartName);
      var svg = chart.getSVGString();
      svg = svg.replace(/&nbsp;/g, "&#160;");
      return window.btoa(unescape(encodeURIComponent(svg)));
    },
    async publishResults() {
      await this.releaseResults({ surveyId: this.selectedAssessment.id });
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    },
    downloadPdf() {
      const competencyImg = this.exportChart("competency-chart");
      const subcompetencyImg = this.exportChart("subcompetency-chart");
      this.download({
        id: this.$route.params.id,
        teamId:
          this.selectedTeam.id > -1 ? parseInt(this.selectedTeam.id, 10) : null,
        jobId: this.selectedJobrole.id,
        userId: this.selectedMember.id,
        competencyImg,
        subcompetencyImg,
      });
    },
    exportXLS() {
      this.export({
        id: this.$route.params.id,
        teamId:
          this.selectedTeam.id > -1 ? parseInt(this.selectedTeam.id, 10) : null,
        jobId: this.selectedJobrole.id,
        userId: this.selectedMember.id,
      });
    },
    fetchResults() {
      this.getAssessmentCompetencyResults({
        id: this.$route.params.id,
        teamId: this.selectedTeam?.id,
        jobId: this.selectedJobrole.id,
        userId: this.selectedMember.id,
      });
      this.getAssessmentSubcompetencyResults({
        id: this.$route.params.id,
        teamId: this.selectedTeam?.id,
        jobId: this.selectedJobrole.id,
        userId: this.selectedMember.id,
      });
    },
    async init() {
      if (!this.$route.params.id) return;
      await this.getAssessment({ id: this.$route.params.id });
      await this.getCompanyAssessments();
      this.selectedAssessment = {
        id: this.assessment.id,
        label: this.assessment.title + this.displayVersionForSeller(this.assessment.productVersion),
      };
      await this.getTeamsForAssessment({ id: this.$route.params.id });
      await this.getProgress({
        surveyId: this.$route.params.id,
      });
      await this.getMembersOfSurvey({
        surveyId: this.$route.params.id,
        teamId: this.selectedTeam.id,
        jobRoleId: this.selectedJobrole.id,
      });
      await this.getSurveyStats({
        surveyId: this.selectedAssessment.id,
        teamId: this.selectedTeam.id,
        jobRoleId: this.selectedJobrole.id,
      });
      this.selectedTeam = this.teamsOptions.find(
        (el) => el.id === parseInt(this.selectedTeam.id, 10)
      );
      await this.getJobRoles({
        surveyId: this.$route.params.id,
        teamId: this.selectedTeam?.id,
      });
      this.fetchResults();
    },
    async setAssessmentValue(val) {
      this.selectedAssessment = val;
      if (!this.teamsOptions.find((el) => el.id === this.selectedTeam.id)) {
        await this.setTeamValue({ label: "All Participants", id: -1 }, true);
      }
      await this.setJobRole({ label: "All Job Roles", id: 0 });
      await this.setEmployee({ label: "All Employees", id: 0  });
      this.$router.push({
        name: "CompanyAssessmentResult",
        params: { id: val.id },
      });
    },
    async setTeamValue(val, flag) {
      this.selectedTeam = val;
      await this.setJobRole({ label: "All Job Roles", id: 0 }, flag);
      await this.getJobRoles({
        surveyId: this.$route.params.id,
        teamId: this.selectedTeam?.id,
      });
    },
    async setJobRole(val, flag) {
      this.selectedJobrole = val;
      if (!flag) {
        await this.setEmployee({ label: "All Employees", id: 0  }, true);
      }
      await this.getMembersOfSurvey({
        surveyId: this.$route.params.id,
        teamId: this.selectedTeam.id,
        jobRoleId: this.selectedJobrole.id,
      });
      await this.getSurveyStats({
        surveyId: this.$route.params.id,
        teamId: this.selectedTeam.id,
        jobRoleId: this.selectedJobrole.id,
      });
      // this.fetchResults();
    },
    async setEmployee(val, flag) {
      this.selectedMember = val;
      if (!flag) {
        if (!this.member || this.member.teams?.length > 1) {
          await this.setTeamValue({ label: "All Participants", id: -1 }, true);
        } else if (this.member?.teams && this.member.teams.length === 1) {
          const selected = this.teamsOptions.find(el => el.id === this.member.teams[0].id);
          await this.setTeamValue(selected, true);
        }
        if (val.id === 0) await this.setJobRole({ label: "All Job Roles", id: 0 });
        if (this.selectedJobrole.id === 0 && val.id !== 0) {
          const role = {
            label: this.member.jobRoleName,
            id: this.member.jobRoleId,
          };
          await this.setJobRole(role, true);
        }
      }
      this.fetchResults();
    },
  },
  async created() {
    if (this.$route.params.id === "undefined") {
      await this.$router.push("/company-results-empty");
    }
    await this.init();
  },
  async mounted() {
    scrollToTop();
  },
};
</script>

<style></style>
